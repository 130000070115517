.avatarInstagramStyle {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  height: 40;
  width: 40;
}
.paperStyling {
  padding: 20px;
  height: 32vh;
  width: 350px;
  margin: 250px auto;
}
h1 {
  text-align: center;
  margin-top: 8px;
}
.gridStyle {
  margin: 100px;
}
.btnstyle {
  margin: 108px;
}
.paperStyle {
  padding: 20px;
  height: 138vh;
  width: 350px;
  margin: 20px auto;
}
.hl {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  border: 2px solid #ddd;
  width: 300px;
}
.hl-innertext {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 8px 10px;
}
.ul {
  margin-top: -30px;
}
.dl {
  margin-top: 191px;
}
.formatting {
  padding: 10px;
}
input,
.btn {
  width: 100%;
  padding: 5px;
  border: none;
  border-radius: 4px;
  margin: 5px 0;
  opacity: 0.85;
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
}
input:hover,
.btn:hover {
  opacity: 1;
}
/* .fb {
  background-color: #3b5998;
  color: white;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: -40px;
  margin-right: 195px;
  width: 106%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
.twitter {
  background-color: #55acee;
  color: white;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: -40px;
  margin-right: 195px;
  width: 106%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
.google {
  background-color: #dd4b39;
  color: white;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: -40px;
  margin-right: 195px;
  width: 106%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
.instagram {
  background: "#f09433";
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  color: white;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: -40px;
  margin-right: 195px;
  width: 106%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
.col {
  float: left;
  margin: auto;
  padding: 0 50px;
  margin-top: 6px;
}
.row:after {
  content: "";
  display: table;
  clear: both;
} */
